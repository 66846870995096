import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import Sidebar from '../Sidebar.js/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { getTestList } from '../../redux/getTestListSlice';
import { addTest } from '../../redux/addTestSlice';
import { updateTest } from '../../redux/updateTestSlice';
import { getCategoryList } from '../../redux/getCategoryListSlice';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import PaginationComponent from '../Pagination/PaginationComponent';
import { ClipLoader } from "react-spinners";

const modal_setting = {
    content: {
        top: "20%",
        left: "50%",
        right: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, 0)",
        maxWidth: "100%",
        width: "420px",
        borderRadius: "8px",
    },
};

const modalDelete = {
    content: {
        position: 'absolute',
        top: '50%',
        left: "50%",
        right: "auto",
        marginRight: "-50%",
        transform: 'translate(-50%, -50%)',
        maxWidth: "100%",
        width: "420px",
        borderRadius: "8px",
        padding: '20px',
        textAlign: 'center',
    },
};


const Test = () => {

    const dispatch = useDispatch();
    const [skip, setSkip] = useState(0)
    const [isOpen, setOpen] = useState(false);
    const [test, setTest] = useState([]);
    const [filteredTests, setFilteredTests] = useState([]);
    const [categories, setCategories] = useState([]);
    const [name, setName] = useState("");
    const [amount, setAmount] = useState("");
    const [from, setFrom] = useState(0)
    const [testId, setTestId] = useState()
    const [count, setCount] = useState(1)
    const [searchTerm, setSearchTerm] = useState("");
    const [isOpenDelete, setOpenDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isOpenSuccess, setOpenSuccess] = useState(false);
    const [isOpenSuccessUpdate, setOpenSuccessUpdate] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);

    const getTestListSuccess = useSelector((state) => state.getTestListReducer.data);
    const addTestSuccess = useSelector((state) => state.addTestReducer.data);
    const updateTestSuccess = useSelector((state) => state.updateTestReducer.data);

    const getCategoryListSuccess = useSelector((state) => state.getCategoryListReducer.data);

    useEffect(() => {
        if (getTestListSuccess != null && getTestListSuccess.status == 1) {
            setLoaderVisible(false);
            setTest(getTestListSuccess.data);
            const count = Math.floor(getTestListSuccess.data.length / 20)
            setCount(count + 1)
        } else {
            setLoaderVisible(false)
        }
    }, [getTestListSuccess]);

    useEffect(() => {
        setLoaderVisible(true);
        const paylaod = {
            skip: skip,
        }
        dispatch(getTestList(paylaod));

    }, [skip]);

    const onSubmitClick = () => {
        if (name.length == 0) {
            alert("Please enter name!");
        } else if (amount.length == 0) {
            alert("Please enter amount");
        } else {
            if (from == 0) {
                const payload = {
                    name: name,
                    amount: amount,
                    categoryId: selectedOption
                };
                setOpen(false);
                dispatch(addTest(payload));
                setTimeout(() => {
                    setOpenSuccess(true)
                }, 500);
            }
            else {
                const payload = {
                    testId: testId,
                    name: name,
                    amount: amount,
                };
                setOpen(false);
                dispatch(updateTest(payload))
                setTimeout(() => {
                    setOpenSuccessUpdate(true)
                }, 500);
            }
        }
    };

    const toggleSwitch = (item) => {
        const payload = {
            name: item.name,
            amount: item.amount,
            testId: item._id,
            isActive: item.isActive == 1 ? 0 : 1,
            isDeleted: item.isDeleted
        }
        dispatch(updateTest(payload))
    };

    useEffect(() => {
        console.log("addTestSuccess  ===>", addTestSuccess)
        if (addTestSuccess != null && addTestSuccess.status == 1) {
            const paylaod = {
                skip: skip,
            }
            dispatch(getTestList(paylaod));
        }
    }, [addTestSuccess]);


    const onDeleteClick = () => {
        if (selectedItem) {
            const payload = {
                name: selectedItem.name,
                amount: selectedItem.amount,
                testId: selectedItem._id,
                isActive: 0,
                isDeleted: 1,
            }
            dispatch(updateTest(payload))
            setOpenDelete(false);
            setSelectedItem(null);
        }
    }

    const onEditClick = (item) => {
        setFrom(1)
        setName(item.name);
        setTestId(item._id);
        setAmount(item.amount);
        setOpen(true);
    }

    const onAddClick = () => {
        setFrom(0)
        setSelectedOptionName("")
        setName("");
        setAmount("");
        setOpen(true);
    }

    useEffect(() => {
        if (updateTestSuccess != null && updateTestSuccess.status == 1) {
            const paylaod = {
                skip: 0,
            }
            dispatch(getTestList(paylaod));
        }
    }, [updateTestSuccess])

    useEffect(() => {
        const paylaod = {
            skip: 0,
        }
        dispatch(getCategoryList(paylaod));
    }, [])

    useEffect(() => {
        if (getCategoryListSuccess != null && getCategoryListSuccess.status == 1) {
            setCategories(getCategoryListSuccess.data);
        }
    }, [getCategoryListSuccess]);

    const handleSubmit = (event) => {
        event.preventDefault();
        alert(`Selected option: ${selectedOption, selectedOptionName}`);
    };

    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOptionName, setSelectedOptionName] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleInputChange = (event) => {
        setSelectedOptionName(event.target.value);
        setIsDropdownOpen(true);
    };

    const handleSelectChange = (item) => {
        setSelectedOption(item._id);
        setSelectedOptionName(item.name);
        setIsDropdownOpen(false);
    };

    const filteredOptions = getCategoryListSuccess?.data.filter(item =>
        item.name.toLowerCase().includes(selectedOptionName.toLowerCase())
    );


    // const [selected, setSelected] = useState('Aaaaa');

    // const handleSelect = (event) => {
    //     setSelected(event.target.value);
    // };


    useEffect(() => {
        if (searchTerm.length > 0) {
            setFilteredTests(
                test.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        } else {
            setFilteredTests(test);
        }
    }, [searchTerm, test]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };


    return (
        <>
            <div className="dashboard">
                {/* <Sidebar /> */}
                <div className='table_categories'>
                    <div className='categories_head'>
                        <div className='content_dropdown'>
                            <h2>Test</h2>
                            {/* <div className="selected_otion">
                                <select value={selected} onChange={handleSelect} className="custom-dropdown-toggle">
                                    <option value="Aaaaa">Aaaaa</option>
                                    <option value="Bbbbb">Bbbbb</option>
                                    <option value="Ccccc">Ccccc</option>
                                </select>
                            </div> */}
                        </div>
                        <div className='search_categories_btn'>
                            <div className="box">
                                <input required type="search" className="input" id="search"
                                    placeholder='Search...'
                                    autoComplete='off'
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                            </div>
                            <Button type='button' onClick={() => onAddClick()}>Add Test</Button>
                        </div>
                    </div>

                    <Table responsive bordered>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Option</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTests.length > 0 ?
                                (
                                    <>
                                        {filteredTests.map((item) => (
                                            <tr >
                                                <td>{item.name}</td>
                                                <td>{item.amount}</td>
                                                <td>
                                                    <div className='switch_btn_center'>
                                                        <div className={`switch ${item.isActive ? 'on' : 'off'}`} onClick={(val) => toggleSwitch(item)}>
                                                            <div className="toggle"></div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <EditIcon onClick={() => onEditClick(item)} />
                                                    <DeleteForeverIcon onClick={() => { setSelectedItem(item); setOpenDelete(true) }} style={{ marginLeft: 20 }} />
                                                </td>
                                            </tr>
                                        ))}
                                        <div className="bottom_pagination">
                                            <PaginationComponent
                                                skip={skip}
                                                setSkip={setSkip}
                                                count={count}
                                            />
                                        </div>
                                    </>
                                )
                                : (
                                    loaderVisible && (
                                        <tr>
                                            <td colSpan="6" className="text-center">
                                                <ClipLoader color="#000" />
                                            </td>
                                        </tr>
                                    )
                                )}
                        </tbody>
                    </Table>

                    <Modal
                        isOpen={isOpen}
                        style={modal_setting}
                        onRequestClose={() => setOpen(false)}
                    >
                        <div className='modal_content_center'>
                            <div className="modal_header">
                                <h2>Add Test</h2>
                                <button onClick={() => setOpen(false)} className="close_button">
                                    &times;
                                </button>
                            </div>
                            <form onSubmit={handleSubmit}>
                                {from ? "" :
                                    <div>
                                        <label htmlFor="dropdown-input">Category <span style={{ color: '#10519e' }}>*</span></label><br />
                                        <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                            <input
                                                id="dropdown-input"
                                                type="text"
                                                placeholder='Select Category'
                                                value={selectedOptionName}
                                                onChange={handleInputChange}
                                                style={{ width: '100%' }}
                                            />
                                            <Button
                                                type="button"
                                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                                style={{
                                                    marginLeft: '5px',
                                                    position: 'absolute',
                                                    right: 15,
                                                    top: 20,
                                                }}
                                            >
                                                ▼
                                            </Button>
                                            {isDropdownOpen && (
                                                <ul
                                                    style={{
                                                        position: 'absolute',
                                                        zIndex: 1,
                                                        backgroundColor: 'white',
                                                        border: '1px solid #ccc',
                                                        listStyleType: 'none',
                                                        margin: 0,
                                                        padding: '5px',
                                                        width: '100%',
                                                        maxHeight: '150px',
                                                        overflowY: 'auto',
                                                    }}
                                                >
                                                    {filteredOptions.length > 0 ? (
                                                        filteredOptions.map((item) => (
                                                            <li
                                                                key={item._id}
                                                                onClick={() => handleSelectChange(item)}
                                                                style={{ padding: '5px', cursor: 'pointer' }}
                                                            >
                                                                {item.name}
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <li style={{ padding: '5px' }}>No results found</li>
                                                    )}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                }
                            </form>
                            <div className='flex_input_box'>
                                <div className='head_input_flex'>
                                    <p style={{ marginTop: 16 }}>Name <span >*</span></p>
                                    <input type='text' placeholder='name' autoComplete='off' value={name} onChange={(e) => setName(e.target.value)} /><br />
                                </div>

                                <div className='head_input_flex'>
                                    <p style={{ marginTop: 16 }}>Amount <span>*</span></p>
                                    <input type='number' placeholder='amount' autoComplete='off' value={amount} onChange={(v) => setAmount(v.target.value)} /><br />
                                </div>
                            </div>
                            <div className='submit_btn'>
                                <Button onClick={() => onSubmitClick()}> Submit</Button>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={isOpenDelete}
                        style={modalDelete}
                        onRequestClose={() => setOpenDelete(false)}
                        className="delete_modal"
                    >
                        <div className="modal_content_center">
                            <p>Are you sure you want to delete</p>
                            <div className="button_group">
                                <Button
                                    className='cancel_btn'
                                    style={{ marginLeft: '10px' }}
                                    onClick={() => {
                                        setOpenDelete(false);
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    className='ok_btn'
                                    onClick={() => onDeleteClick()}
                                >
                                    OK
                                </Button>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={isOpenSuccess}
                        style={modalDelete}
                        onRequestClose={() => setOpenSuccess(false)}
                        className="delete_modal"
                    >
                        <div className="modal_content_center">
                            <p>Add Test Successfully</p>
                            <div className="button_group">
                                <Button
                                    className='ok_btn_success'
                                    onClick={() => setOpenSuccess(false)}
                                >
                                    OK
                                </Button>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={isOpenSuccessUpdate}
                        style={modalDelete}
                        onRequestClose={() => setOpenSuccessUpdate(false)}
                        className="delete_modal"
                    >
                        <div className="modal_content_center">
                            <p>Update Successfully</p>
                            <div className="button_group">
                                <Button
                                    className='ok_btn_success'
                                    onClick={() => setOpenSuccessUpdate(false)}
                                >
                                    OK
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default Test;