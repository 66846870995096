import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Sidebar from "../Sidebar.js/Sidebar";
import Modal from "react-modal";
import Form from "react-bootstrap/Form";
import { getDoctorList } from "../../redux/getDoctorListSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCommisionData } from "../../redux/getCommisionSlice";
import {
  clearUpdateCommisionData,
  updateCommision,
} from "../../redux/updateCommisionSlice";
import { ClipLoader } from "react-spinners";
import PaginationComponent from "../Pagination/PaginationComponent";
import { writeFile, utils } from "xlsx";
import { getHospitalList } from "../../redux/getHospitalListSlice";
import {
  clearGetBookingRecordsReportData,
  getBookingRecordsReport,
} from "../../redux/getBookingRecordsReportSlice";
import { clearGetCommisionRecordsReportData } from "../../redux/getCommisionRecordsReportSlice";

const modal_setting = {
  content: {
    top: "20%",
    left: "50%",
    right: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, 0)",
    maxWidth: "100%",
    width: "420px",
    borderRadius: "8px",
  },
};

const Commission = () => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [hospitals, setHospital] = useState([]);
  const [commisions, setCommisions] = useState([]);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [doctorFirstName, setDoctorFirstName] = useState("");
  const [doctorLastName, setDoctorLastName] = useState("");
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(1);
  const [commisionType, setCommisionType] = useState(1);
  const [isOpenCalender, setOpenCalendar] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [commisionRecord, setCommisionRecord] = useState(null);

  const [selectedOptionDoctor, setSelectedOptionDoctor] = useState("");
  const [selectedOptionNameDoctor, setSelectedOptionNameDoctor] = useState("");
  const [isDropdownOpenDoctor, setIsDropdownOpenDoctor] = useState(false);

  const [selectedOptionHospitals, setSelectedOptionHospitals] = useState("");
  const [selectedOptionNameHospitals, setSelectedOptionNameHospitals] =
    useState("");

  const getHospitalListSuccess = useSelector(
    (state) => state.getHospitalListReducer.data
  );
  const getDoctorListResponse = useSelector(
    (state) => state.getDoctorListReducer.data
  );
  const getCommisionResponse = useSelector(
    (state) => state.getCommisionReducer.data
  );
  const updateCommisionResponse = useSelector(
    (state) => state.updateCommisionReducer.data
  );
  const getCommisionRecordsReportResponse = useSelector(
    (state) => state.getCommisionRecordsReportReducer.data
  );

  const filteredOptionsDoctor = getDoctorListResponse?.data.filter((item) =>
    (item.firstName + item.lastName)
      .toLowerCase()
      .includes(selectedOptionNameDoctor.toLowerCase())
  );
  const filteredOptionsHospitals = hospitals.filter((item) =>
    item.name.toLowerCase().includes(selectedOptionNameDoctor.toLowerCase())
  );

  const handleInputChangeDoctor = (event) => {
    setSelectedOptionNameDoctor(event.target.value);
    setIsDropdownOpenDoctor(true);
  };

  const handleSelectChangeDoctor = (item) => {
    setSelectedOptionDoctor(item._id);
    setSelectedOptionNameDoctor(item.firstName + " " + item.lastName);
    setDoctorFirstName(item.firstName);
    setDoctorLastName(item.lastName);
    setIsDropdownOpenDoctor(false);
    const payload = {
      id: item._id,
      type: 1,
    };
    dispatch(getCommisionData(payload));
  };
  const handleSelectChangeHospitals = (item) => {
    setSelectedOptionNameHospitals(item._id);
    setSelectedOptionNameDoctor(item.name);
    setIsDropdownOpenDoctor(false);
    const payload = {
      id: item._id,
      type: 2,
    };
    dispatch(getCommisionData(payload));
  };

  useEffect(() => {
    setLoaderVisible(true);
    const paylaod = {
      skip: skip,
    };
    dispatch(getCommisionData(paylaod));
  }, [skip]);

  useEffect(() => {
    const paylaod = {
      skip: -1,
    };
    dispatch(getDoctorList(paylaod));
    dispatch(getHospitalList(paylaod));
  }, []);

  useEffect(() => {
    const paylaod = {
      type: -1,
    };
    dispatch(getCommisionData(paylaod));
  }, []);

  useEffect(() => {
    console.log("getDoctorListResponse ===> ", getDoctorListResponse);
    if (getDoctorListResponse != null && getDoctorListResponse.status == 1) {
      setDoctors(getDoctorListResponse.data);
    }
  }, [getDoctorListResponse]);

  useEffect(() => {
    console.log("getHospitalListSuccess ===> ", getHospitalListSuccess);
    if (getHospitalListSuccess != null && getHospitalListSuccess.status == 1) {
      setHospital(getHospitalListSuccess.data);
    }
  }, [getHospitalListSuccess]);

  useEffect(() => {
    console.log("getCommisionResponse ===> ", getCommisionResponse);
    if (getCommisionResponse != null && getCommisionResponse.status === 1) {
      setLoaderVisible(false);
      setCommisions(getCommisionResponse.data);
      const count = Math.floor(getCommisionResponse.data.length / 20);
      setCount(count + 1);
    } else {
      setLoaderVisible(false);
    }
  }, [getCommisionResponse]);

  const [isPaymentDone, setPaymentDone] = useState(0);
  const [commisionId, setCommisionIdId] = useState("");

  const onPaymentDoneChange = (item) => {
    if (item.isPaymentDone != 1) {
      console.log("onPaymentDoneChange.isPaymentDone---", item.isPaymentDone);
      const isPaymentDone = item.isPaymentDone == 0 ? 1 : 0;
      setCommisionIdId(item._id);
      setPaymentDone(isPaymentDone);
      const payload = {
        isPaymentDone: isPaymentDone,
        commisionId: item._id,
      };
      setPaymentDone(item._id);
      dispatch(updateCommision(payload));
    }
  };

  useEffect(() => {
    if (
      updateCommisionResponse != null &&
      updateCommisionResponse.status === 1
    ) {
      alert("Update Success!");
      setCommisions((prevCommision) =>
        prevCommision.map((commision) =>
          commision._id === commisionId
            ? { ...commision, isPaymentDone: isPaymentDone }
            : commision
        )
      );
    }

    return () => {
      dispatch(clearUpdateCommisionData());
    };
  }, [updateCommisionResponse]);

  const onTypeSelect = (type) => {
    console.log("Type ===> ", type);
    setCommisionType(type);
    setSelectedOptionNameDoctor("");
  };

  useEffect(() => { }, [commisionType]);

  const ExportToExcel = (data) => {
    console.log("Export Data ===>", data);
    if (data != null && data.length > 0) {
      const worksheet = utils.json_to_sheet(data);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Sheet1");
      writeFile(workbook, "data.xlsx");
    }
    setCommisionRecord(null);
  };

  useEffect(() => {
    if (
      getCommisionRecordsReportResponse != null &&
      getCommisionRecordsReportResponse.status === 1
    ) {
      setOpenCalendar(false);
      setStartDate("");
      setEndDate("");
      setCommisionRecord(getCommisionRecordsReportResponse.data);
    }
    return () => {
      dispatch(clearGetCommisionRecordsReportData());
    };
  }, [getCommisionRecordsReportResponse]);

  const onSubmitDateClick = () => {
    const paylaod = {
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(getBookingRecordsReport(paylaod));
  };

  useEffect(() => {
    if (commisionRecord != null) {
      ExportToExcel(commisionRecord);
    }
  }, [commisionRecord]);

  return (
    <>
      <div className="dashboard">
        {/* <Sidebar /> */}
        <div className="table_categories">
          <div className="categories_head commission_head">
            <div className="content_dropdown flex_column">
              <div style={{ display: "flex", alignItems: "center" }}>
                <h2>Commission</h2>
                {/* <div>
                  <Button onClick={() => setOpenCalendar(true)}>Export to Excel</Button>
                </div> */}
              </div>
              <Form onChange={(e) => onTypeSelect(e.target.id)}>
                <div key="1" className="checkbox_flex">
                  <Form.Check
                    type="radio"
                    id="1"
                    label="Consultant"
                    name="userType"
                    defaultChecked
                  />
                </div>

                <div key="2" className="checkbox_flex">
                  <Form.Check
                    type="radio"
                    id="2"
                    label="Corporate"
                    name="userType"
                  />
                </div>
              </Form>
            </div>

            <div className="search_categories_btn" style={{ marginRight: 20 }}>
              <div>
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    width: "100%",
                  }}
                >
                  <input
                    id="dropdown-input"
                    type="text"
                    placeholder={
                      commisionType == 1 ? "Search Doctor" : "Search Corporate"
                    }
                    value={selectedOptionNameDoctor}
                    onChange={handleInputChangeDoctor}
                    className="search_input_dropdown_doctor"
                    autoComplete="off"
                  />
                  <Button
                    type="button"
                    onClick={() =>
                      setIsDropdownOpenDoctor(!isDropdownOpenDoctor)
                    }
                    style={{
                      marginLeft: "5px",
                      right: 12,
                      background: "transparent",
                      border: "1px solid #898686",
                      color: "#898686",
                      padding: 3,
                      borderRadius: 4,
                      position: "absolute",
                      top: 5,
                    }}
                  >
                    ▼
                  </Button>
                  {isDropdownOpenDoctor && (
                    <ul
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        backgroundColor: "white",
                        border: "1px solid #ccc",
                        listStyleType: "none",
                        margin: 0,
                        padding: "5px",
                        width: "100%",
                        maxHeight: "150px",
                        overflowY: "auto",
                      }}
                    >
                      {commisionType == 1 ? (
                        filteredOptionsDoctor.length > 0 ? (
                          filteredOptionsDoctor.map((item) => (
                            <li
                              key={item._id}
                              onClick={() => handleSelectChangeDoctor(item)}
                              style={{ padding: "5px", cursor: "pointer" }}
                            >
                              {item.firstName + " " + item.lastName}
                            </li>
                          ))
                        ) : (
                          <li style={{ padding: "5px" }}>No results found</li>
                        )
                      ) : filteredOptionsHospitals.length > 0 ? (
                        filteredOptionsHospitals.map((item) => (
                          <li
                            key={item._id}
                            onClick={() => handleSelectChangeHospitals(item)}
                            style={{ padding: "5px", cursor: "pointer" }}
                          >
                            {item.name}
                          </li>
                        ))
                      ) : (
                        <li style={{ padding: "5px" }}>No results found</li>
                      )}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Table responsive bordered>
            <thead className="tabel_head">
              <tr>
                <th>Name</th>
                <th>Booking Id</th>
                <th>Commission</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {commisions.length > 0 ? (
                <>
                  {commisions.map((item) => (
                    <tr>
                      <td>
                        {" "}
                        {item.userId.firstName + " " + item.userId.lastName}
                      </td>
                      <td>{item.bookingId.bookingId}</td>
                      <td>{item.commisionAmount}</td>
                      <td>
                        <Button
                          onClick={() =>
                            item.isPaymentDone == 0
                              ? onPaymentDoneChange(item)
                              : ""
                          }
                          style={{
                            WebkitTextStroke: "aliceblue",
                            width: 100,
                            backgroundColor:
                              item.isPaymentDone == 0 ? "red" : "green",
                            marginLeft: 0,
                            border: "transparent",
                          }}
                          className="content_"
                        >
                          {item.isPaymentDone == 0 ? "Un Paid" : "Paid"}
                        </Button>
                      </td>
                    </tr>
                  ))}
                  <div className="bottom_pagination">
                    <PaginationComponent
                      skip={skip}
                      setSkip={setSkip}
                      count={count}
                    />
                  </div>
                </>
              ) : (
                loaderVisible && (
                  <>
                    <tr>
                      <td colSpan="6" className="text-center">
                        <ClipLoader color="#000" />
                      </td>
                    </tr>
                  </>
                )
              )}
            </tbody>
          </Table>

          <Modal
            isOpen={isOpen}
            style={modal_setting}
            onRequestClose={() => setOpen(false)}
          >
            <div className="modal_content_center">
              <form>
                <div className="flex_input_box">
                  <div className="head_input_flex">
                    <p>
                      Name <span>*</span>
                    </p>
                    <input
                      type="text"
                      placeholder="Name"
                      autoComplete="off"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <br />
                  </div>
                  <div className="head_input_flex">
                    <p>
                      Email <span>*</span>
                    </p>
                    <input
                      type="text"
                      placeholder="Email"
                      autoComplete="off"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <br />
                  </div>
                </div>

                <div className="flex_input_box">
                  <div className="head_input_flex">
                    <p style={{ marginTop: 8 }}>
                      Mobile Number <span>*</span>
                    </p>
                    <input
                      type="number"
                      placeholder="Mobile Number"
                      autoComplete="off"
                      value={mobileNumber}
                      onChange={(v) => setMobileNumber(v.target.value)}
                    />
                    <br />
                  </div>
                  <div className="head_input_flex">
                    <p style={{ marginTop: 8 }}>
                      Address <span>*</span>
                    </p>
                    <input
                      type="text"
                      placeholder="Address"
                      autoComplete="off"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <br />
                  </div>
                </div>
              </form>

              <div className="submit_btn">
                <Button> Submit</Button>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={isOpenCalender}
            style={modal_setting}
            onRequestClose={() => setOpenCalendar(false)}
            className="calendar_width"
          >
            <div className="modal_content_center">
              <form>
                <div className="flex_input_box">
                  <div className="head_input_flex">
                    <p>
                      From <span>*</span>
                    </p>
                    <input
                      type="date"
                      autoComplete="off"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                    <br />
                  </div>
                </div>
                <div className="flex_input_box">
                  <div className="head_input_flex">
                    <p>
                      To <span>*</span>
                    </p>
                    <input
                      type="date"
                      autoComplete="off"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                    <br />
                  </div>
                </div>
              </form>

              <div className="submit_btn">
                <Button
                  type="button"
                  onClick={() => onSubmitDateClick()}
                  style={{ letterSpacing: 0.4, fontWeight: "600" }}
                >
                  Export
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Commission;
