import React, { useEffect, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import Sidebar from '../Sidebar.js/Sidebar';
import Modal from 'react-modal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientList } from '../../redux/getPatientListSlice';
import { addPatient } from '../../redux/addPatientSlice';
import { updatePatient } from '../../redux/updatePatientSlice';
import { ClipLoader } from "react-spinners";
import PaginationComponent from '../Pagination/PaginationComponent';

const modal_setting = {
    content: {
        top: "20%",
        left: "50%",
        right: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, 0)",
        maxWidth: "100%",
        width: "420px",
        borderRadius: "8px",
    },
};

const modalDelete = {
    content: {
        position: 'absolute',
        top: '50%',
        left: "50%",
        right: "auto",
        marginRight: "-50%",
        transform: 'translate(-50%, -50%)',
        maxWidth: "100%",
        width: "420px",
        borderRadius: "8px",
        padding: '20px',
        textAlign: 'center',
    },
};

const Patient = () => {

    const dispatch = useDispatch();

    const [loaderVisible, setLoaderVisible] = useState(false)
    const [patient, setPatient] = useState([]);
    const [patientId, setPatientID] = useState("");
    const [skip, setSkip] = useState(0);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [address, setAddress] = useState("");
    const [from, setFrom] = useState(0);
    const [isOpen, setOpen] = useState(false);
    const [patientGender, setPatientGender] = useState(0)
    const [count, setCount] = useState(1)
    const [searchTerm, setSearchTerm] = useState("");
    const [isOpenDelete, setOpenDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isOpenSuccess, setOpenSuccess] = useState(false);
    const [isOpenSuccessUpdate, setOpenSuccessUpdate] = useState(false);

    const getPatientListSuccess = useSelector((state) => state.getPatientListReducer.data);
    const addPatientSuccess = useSelector((state) => state.addPatientReducer.data);
    const updatePatientSuccess = useSelector((state) => state.updatePatientReducer.data);


    useEffect(() => {
        if (getPatientListSuccess != null && getPatientListSuccess.status == 1) {
            setLoaderVisible(false)
            setPatient(getPatientListSuccess.data);
            const count = Math.floor(getPatientListSuccess.data.length / 20)
            setCount(count + 1)
        }
        else {
            setLoaderVisible(false)
        }
    }, [getPatientListSuccess]);

    useEffect(() => {
        setLoaderVisible(true)
        const paylaod = {
            skip: skip,
        }
        dispatch(getPatientList(paylaod));
    }, [skip]);

    const onSubmitClick = () => {

        if (firstName.length == 0) {
            alert("Please enter firstName");
        } else if (lastName.length == 0) {
            alert("Please enter lastName");
        } else if (email.length == 0) {
            alert("Please enter email");
        } else if (mobileNumber.length == 0) {
            alert("Please enter mobile number");
        } else if (address.length == 0) {
            alert("Please enter address");
        } else {
            if (from == 0) {
                const payload = {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    mobileNumber: mobileNumber,
                    address: address,
                    gender: patientGender
                };
                setOpen(false);
                setTimeout(() => {
                    setOpenSuccess(true)
                }, 500);
                dispatch(addPatient(payload));
            }
            else {
                const payload = {
                    patientId: patientId,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    mobileNumber: mobileNumber,
                    address: address,
                    gender: patientGender
                };
                setOpen(false);
                console.log("Update Patient ===>", payload)
                setTimeout(() => {
                    setOpenSuccessUpdate(true)
                }, 500);
                dispatch(updatePatient(payload))
            }
        }
    };

    const toggleSwitch = (item) => {
        const payload = {
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            mobileNumber: item.mobileNumber,
            address: item.address,
            patientId: item._id,
            isActive: item.isActive == 1 ? 0 : 1,
            isDeleted: item.isDeleted
        }
        console.log("payload ==>", payload)
        dispatch(updatePatient(payload))
    };

    useEffect(() => {
        console.log("addPatientSuccess  ===>", addPatientSuccess)
        if (addPatientSuccess != null && addPatientSuccess.status == 1) {
            const paylaod = {
                skip: skip,
            }
            dispatch(getPatientList(paylaod));
        }
    }, [addPatientSuccess]);

    const onDeleteClick = () => {
        if (selectedItem) {
            const payload = {
                firstName: selectedItem.firstName,
                lastName: selectedItem.lastName,
                email: selectedItem.email,
                mobileNumber: selectedItem.mobileNumber,
                address: selectedItem.address,
                patientId: selectedItem._id,
                isActive: 0,
                isDeleted: 1,
            }
            dispatch(updatePatient(payload))
        }
        setOpenDelete(false);
        setSelectedItem(null);
    }

    const onEditClick = (item) => {
        setFrom(1)
        setFirstName(item.firstName)
        setLastName(item.lastName)
        setEmail(item.email)
        setMobileNumber(item.mobileNumber)
        setAddress(item.address)
        setPatientID(item._id)
        setOpen(true)
    }

    const onAddClick = () => {
        setFrom(0)
        setFirstName("")
        setLastName("")
        setEmail("")
        setMobileNumber("")
        setAddress("")
        setOpen(true)
    }

    useEffect(() => {
        if (updatePatientSuccess != null && updatePatientSuccess.status == 1) {
            setFirstName("")
            setLastName("")
            setMobileNumber("")
            setAddress("")
            const paylaod = {
                skip: 0,
            }
            dispatch(getPatientList(paylaod));
        }
    }, [updatePatientSuccess])


    const filteredPatients = patient.filter(item =>
        item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.mobileNumber.includes(searchTerm)
    );

    return (
        <>
            <div className="dashboard">
                {/* <Sidebar /> */}
                <div className='table_categories'>
                    <div className='categories_head'>
                        <div className='content_dropdown'>
                            <h2>Patient</h2>
                        </div>
                        <div className='search_categories_btn'>
                            <div className="box">
                                <input required type="search" className="input" id="search"
                                    autoComplete='off'
                                    placeholder='Searching...'
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <Button type='button' onClick={() => onAddClick()}>Add Patient</Button>
                        </div>
                    </div>

                    <Table responsive bordered>
                        <thead className='tabel_head'>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile Number</th>
                                <th>Address</th>
                                <th>Status</th>
                                <th>Option</th>
                            </tr>
                        </thead>

                        <tbody>
                            {filteredPatients.length > 0 ?
                                (
                                    <>
                                        {filteredPatients.map((item) => (
                                            <tr >
                                                <td>{item.firstName + " " + item.lastName}</td>
                                                <td> {item.email}</td>
                                                <td>{item.mobileNumber}</td>
                                                <td>{item.address}</td>
                                                <td>
                                                    <div className='switch_btn_center'>
                                                        <div className={`switch ${item.isActive ? 'on' : 'off'}`} onClick={() => toggleSwitch(item)}>
                                                            <div className="toggle"></div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <EditIcon onClick={() => onEditClick(item)} />
                                                    <DeleteForeverIcon onClick={() => { setSelectedItem(item); setOpenDelete(true) }} style={{ marginLeft: 20 }} />
                                                </td>
                                            </tr>
                                        ))}
                                        < div className="bottom_pagination">
                                            <PaginationComponent
                                                skip={skip}
                                                setSkip={setSkip}
                                                count={count}
                                            />
                                        </div>
                                    </>
                                )
                                : (
                                    loaderVisible && <tr>
                                        <td colSpan="6" className="text-center">
                                            <ClipLoader color="#000" />
                                        </td>
                                    </tr>
                                )}
                        </tbody>

                    </Table>

                    <Modal
                        isOpen={isOpen}
                        style={modal_setting}
                        onRequestClose={() => setOpen(false)}
                    >
                        <div className='modal_content_center'>
                            <div className="modal_header">
                                <h2>Add Patient</h2>
                                <button onClick={() => setOpen(false)} className="close_button">
                                    &times;
                                </button>
                            </div>
                            <form>
                                <div className='flex_input_box'>
                                    <div className='head_input_flex'>
                                        <p>First Name <span>*</span></p>
                                        <input type='text' placeholder='First Name' autoComplete='off' value={firstName} onChange={(e) => setFirstName(e.target.value)} /><br />
                                    </div>

                                    <div className='head_input_flex'>
                                        <p>Last Name <span>*</span></p>
                                        <input type='text' placeholder='Last Name' autoComplete='off' value={lastName} onChange={(e) => setLastName(e.target.value)} /><br />
                                    </div>
                                </div>

                                <div className='flex_input_box'>
                                    <div className='head_input_flex'>
                                        <p style={{ marginTop: 8 }}>Email <span>*</span></p>
                                        <input type='text' placeholder='Email' autoComplete='off' value={email} onChange={(e) => setEmail(e.target.value)} /><br />
                                    </div>

                                    <div className='head_input_flex'>
                                        <p style={{ marginTop: 8 }}>Mobile Number <span>*</span></p>
                                        <input type='number' placeholder='Mobile Number' autoComplete='off' value={mobileNumber} onChange={(v) => {
                                            if (v.target.value.length <= 10 && /^\d*$/.test(v.target.value)) {
                                                setMobileNumber(v.target.value);
                                            }
                                        }
                                        } /><br />
                                    </div>
                                </div>

                                <div className='flex_input_box'>
                                    <div className='head_input_flex' >
                                        <p style={{ marginTop: 8 }}>Address <span>*</span></p>
                                        <input type='text' placeholder='Address' autoComplete='off' value={address} onChange={(e) => setAddress(e.target.value)} /><br />
                                    </div>

                                    <div className='head_input_flex'>
                                        <Form.Label style={{ marginTop: 8 }}>Gender <span style={{ color: '#10519e' }}>*</span></Form.Label>
                                        <Form.Select aria-label="Default select example" value={patientGender} onChange={(e) => setPatientGender(e.target.value)}>
                                            <option>Select Gender</option>
                                            <option value="1">Male</option>
                                            <option value="2">Female</option>
                                            <option value="3">Other</option>
                                        </Form.Select>
                                    </div>
                                </div>



                            </form>

                            <div className='submit_btn'>
                                <Button onClick={() => onSubmitClick()}> Submit</Button>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={isOpenDelete}
                        style={modalDelete}
                        onRequestClose={() => setOpenDelete(false)}
                        className="delete_modal"
                    >
                        <div className="modal_content_center">
                            <p>Are you sure you want to delete</p>
                            <div className="button_group">
                                <Button
                                    className='cancel_btn'
                                    style={{ marginLeft: '10px' }}
                                    onClick={() => {
                                        setOpenDelete(false);
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    className='ok_btn'
                                    onClick={() => onDeleteClick()}
                                >
                                    OK
                                </Button>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={isOpenSuccess}
                        style={modalDelete}
                        onRequestClose={() => setOpenSuccess(false)}
                        className="delete_modal"
                    >
                        <div className="modal_content_center">
                            <p>Add Patient Successfully</p>
                            <div className="button_group">
                                <Button
                                    className='ok_btn_success'
                                    onClick={() => setOpenSuccess(false)}
                                >
                                    OK
                                </Button>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={isOpenSuccessUpdate}
                        style={modalDelete}
                        onRequestClose={() => setOpenSuccessUpdate(false)}
                        className="delete_modal"
                    >
                        <div className="modal_content_center">
                            <p>Update Successfully</p>
                            <div className="button_group">
                                <Button
                                    className='ok_btn_success'
                                    onClick={() => setOpenSuccessUpdate(false)}
                                >
                                    OK
                                </Button>
                            </div>
                        </div>
                    </Modal>

                </div >
            </div >
        </>
    );
};

export default Patient;