import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { feedback } from '../../redux/getFeedbackSlice';
import PaginationComponent from '../Pagination/PaginationComponent';
import { ClipLoader } from 'react-spinners';

const Feedback = () => {

    const dispatch = useDispatch()
    const [skip, setSkip] = useState(0);
    const [count, setCount] = useState(1)
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [feedbackList, setFeedbackList] = useState([])
    const [searchTerm, setSearchTerm] = useState("");

    const feedbackListResponse = useSelector((state) => state.feedbackReducer.data);

    useEffect(() => {
        setLoaderVisible(true);
        const paylaod = {
            skip: skip,
        }
        dispatch(feedback(paylaod));
    }, [skip]);

    useEffect(() => {
        dispatch(feedback())
    }, [])

    useEffect(() => {
        console.log("feedbackListResponse ===> ", feedbackListResponse)
        if (feedbackListResponse != null && feedbackListResponse.status == 1) {
            setFeedbackList(feedbackListResponse.data)
            const count = Math.floor(feedbackListResponse.data.length / 20)
            setCount(count + 1)
        }
        else {
            setLoaderVisible(false);
        }
    }, [feedbackListResponse])


    const convertDate = (isoString) => {
        const date = new Date(isoString);

        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        };

        const formattedDate = date.toLocaleString('en-GB', options);
        return formattedDate.replace(',', '');
    };

    const filteredContacts = feedbackList.filter((item) => {
        if (item.userId && searchTerm.trim() !== "") {
            const fullName = item.userId.firstName + " " + item.userId.lastName;
            return fullName.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return true; // If no search term, return all contacts
    });

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    }

    return (
        <>
            <div className="dashboard">
                <div className='table_categories'>
                    <div className='categories_head'>
                        <h2>Feedback</h2>
                        <div className='search_categories_btn'>
                            <div className="box">
                                <input required type="search" className="input" id="search"
                                    autoComplete='off'
                                    placeholder='Search...'
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <Table responsive bordered >
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Feedback</th>
                                    <th>Date / Time</th>
                                </tr>
                            </thead>


                            <tbody>
                                {filteredContacts.length > 0 ?
                                    (
                                        <>
                                            {filteredContacts.map((item) => (
                                                <tr>
                                                    <td style={{ padding: '15px 10px' }}>{item.userId ? item.userId.firstName + " " + item.userId.lastName : ""}</td>
                                                    <td style={{ padding: '15px 10px' }}>{item.feedback}</td>
                                                    <td style={{ padding: '15px 10px' }}>{convertDate(item.createdAt)}</td>
                                                </tr>
                                            ))}
                                            < div className="bottom_pagination">
                                                <PaginationComponent
                                                    skip={skip}
                                                    setSkip={setSkip}
                                                    count={count}
                                                />
                                            </div>
                                        </>
                                    )
                                    : (
                                        loaderVisible && (
                                            <tr>
                                                <td colSpan="6" className="text-center">
                                                    <ClipLoader color="#000" />
                                                </td>
                                            </tr>
                                        )
                                    )}
                            </tbody>

                        </Table>
                    </div>
                </div >
            </div >
        </>
    );
};

export default Feedback;